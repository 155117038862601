<template>
  <div class="page_bg" style="margin:0;">
    <!-- 顶部返回 -->
    <div class="back_top_name flex-r-sb-c" style=" padding: 0.32rem;background: #ffffff;font-size: 0.48rem;">
      <van-icon name="arrow-left" @click="backPath" />
      <div class="top_name">{{ proSearch.proBrand }}</div>
      <div class="top_icon" />
    </div>
    <!-- 商品列表区域  -->
    <div class="pro_list">
      <MyscrollList ref="myList01" :loading="listLoading" :list="proList" @myload="proload">
        <template #default="slotProps">
          <proCard01 :info="slotProps.item" />
        </template>
      </MyscrollList>
    </div>
  </div>
</template>

<script>
import { mallProduct_searchMallProduct } from '@/api/home'
import MyscrollList from '@/components/MyscrollList'
import proCard01 from '@/components/pro_card_01'
export default {
  components: { MyscrollList, proCard01 },
  data() {
    return {
      // 商品列表
      proSearch: { pageNum: 1, pageSize: 10, proBrand: '' },
      proList: [],
      listLoading: false
    }
  },
  created() {
    this.proSearch.proBrand = this.$route.query.name
    this.getProList()
  },
  activated() {
    console.log('keepalive 进入brandList')
    if (sessionStorage.getItem('listJump')) {
      window.scrollTo({
        top: 0, // 滚动到顶部
        behavior: 'smooth' // 平滑滚动
      })
      sessionStorage.removeItem('listJump')
      this.proSearch.proBrand = this.$route.query.name
      this.getProList()
    }
  },
  deactivated() {
    console.log('keepalive 离开brandList')
  },
  methods: {
    backPath() { window.history.back() },
    getProList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      mallProduct_searchMallProduct(this.proSearch).then(res => {
        this.$toast.clear()
        this.$refs.myList01.load_loading(false)
        this.proList = this.proList.concat(res.data.data)
        if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
          if (res.data.data.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
        }
      })
    },
    proload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.proSearch.pageNum = this.proSearch.pageNum + 1
        this.getProList()
        // 加载状态结束
      }, 50)()
    }
  }
}
</script>

<style>
</style>
